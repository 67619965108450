<template>
    <v-stepper v-model="e1" non-linear class="elevation-0" style="background: transparent">
        <v-alert v-if="partsOrderWarningMessage" border="left" color="blue-grey" dark dense class="mt-5" type="info">
            <!--Parts Order will not be availabe between Dec 1 and 8 Dec because of
            year end inventory reconciliation -->
            {{ partsOrderWarningMessage }}
        </v-alert>
        <v-stepper-items v-if="!isPartOrderPaused">
            <v-stepper-content style="width: 50%; float: right" step="1">
                <div id="model-select-heading">
                    <h2> </h2>
                    <h2 class="heading-line-one"> Please select</h2>
                    <h2 class="heading-line-two"> a model</h2>
                    <h2 class="heading-line-three"> to see available parts.</h2>
                    <div style="margin-top: 50px">
                        <v-btn color="primary" elevation="0" outlined>
                            <h5 class="heading-line-four"
                                ><a href="/parts-order/manual">View Self-Maintainer Manuals</a></h5
                            >
                        </v-btn>
                    </div>
                    <div style="width: 100%; margin-top: 15px">
                        <a href="https://getac.podbean.com/?utm_source=internalsite&utm_medium=banner" target="_blank"
                            ><img style="width: 90%" src="../../assets/banners/11-22_728_x_90-ServicePortal.png"
                        /></a>
                    </div>
                </div>
                <v-card class="mb-12 elevation-0" style="background-color: transparent">
                    <v-simple-table id="demo-data-table" dark>
                        <thead>
                            <tr>
                                <th class="text-center">In Warranty</th>
                                <th class="text-center">Out of Warranty</th>
                                <th class="text-center"> Model </th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in models" :key="index">
                                <td class="text-center"> {{ item.iwQty }}</td>
                                <td class="text-center"> {{ item.oowQty }}</td>
                                <td class="text-center">{{ item.model }}</td>
                                <td class="text-center"
                                    ><v-btn
                                        elevation="0"
                                        color="primary"
                                        text
                                        :disabled="item.iwQty === 0 && !isTraining"
                                        @click="
                                            e1 = 2
                                            getSNList(item.model)
                                        "
                                        >Select</v-btn
                                    ></td
                                >
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
                <div class="container-bg" style="margin-top: 1%; margin: auto">
                    <div class="container-fg">
                        <div style="display: flex; flex-direction: row">
                            <img style="width: 130px; margin-bottom: 1%" src="../../assets/logos/getac-logo.svg" />
                            <div class="text-h7 nav-font pl-3" style="margin-top: 1.3%">PARTS ORDER</div>
                        </div>
                        <v-divider style="margin-bottom: 1%; border-color: gray" />
                        <div>
                            <div style="display: flex; flex-direction: row">
                                <v-list-item-avatar v-if="selectedModel" tile size="150px">
                                    <v-img style="max-width: 100%; height: auto" :src="unitImg"></v-img>
                                </v-list-item-avatar>
                                <div style="display: flex; flex-direction: column">
                                    <h1 style="font-size: 36px; letter-spacing: -0.08rem">
                                        Selected Model: {{ selectedModel }}
                                    </h1>
                                    <p>Please select serial number(s) then add your desired parts to your cart.</p>
                                    <div>
                                        <v-row>
                                            <v-alert dense type="warning">
                                                Please note: any items left in the cart longer than
                                                <strong>2 days</strong> will be removed
                                            </v-alert>
                                        </v-row>
                                        <v-row>
                                            <v-btn
                                                dark
                                                outlined
                                                style="margin-right: 15px"
                                                @click="
                                                    e1 = 1
                                                    selectedSerials = null
                                                    alert = false
                                                "
                                            >
                                                Select Another Model
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                width="250px"
                                                elevation="0"
                                                style="margin-bottom: 25px"
                                                @click="toCart"
                                            >
                                                Checkout Cart
                                            </v-btn>
                                        </v-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-divider style="margin-bottom: 1%; border-color: gray" />
                        <div>
                            <h5 class="white--text mb-2">Select Serial Number(s)</h5>
                        </div>
                        <v-autocomplete
                            v-model="selectedSerials"
                            :items="serials"
                            item-text="sn"
                            dark
                            clearable
                            dense
                            filled
                            @change="getSNParts($event)"
                        >
                            <template v-slot:item="{ item }">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.sn }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.assetTag }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <p style="color: orange">{{ partCounter }}</p>
                        <v-divider style="margin-bottom: 1%; border-color: gray" />
                        <v-container class="pa-0" fluid grid-list-lg>
                            <v-row v-if="modelSerial">
                                <v-col v-for="(item, index) in partsList" :key="index" cols="4">
                                    <div class="product-card-bg">
                                        <div class="product-card">
                                            <div style="display: flex; flex-direction: row">
                                                <v-img
                                                    class="parts-image"
                                                    :src="`https://rma.getac.com` + item.imgUrl"
                                                ></v-img>
                                                <div style="display: flex; flex-direction: column">
                                                    <h4
                                                        style="color: white; font-weight: 400"
                                                        class="text-h7 nav-font mb-3"
                                                    >
                                                        {{ item.partSpec }}</h4
                                                    >
                                                    <v-btn
                                                        color="primary"
                                                        small
                                                        dark
                                                        elevation="0"
                                                        outlined
                                                        style="margin-bottom: 20px; max-width: fit-content"
                                                        :disabled="reachedMaxAmt"
                                                        @click="checkMaxQty(item)"
                                                        >Add to Cart</v-btn
                                                    >
                                                    <p
                                                        v-if="item.isHighValue === 'Y'"
                                                        style="
                                                            font-size: 10px;
                                                            margin-top: -15px;
                                                            text-transform: capitalize;
                                                        "
                                                        >*High Value Part <br />
                                                        (Max: 1 per serial)</p
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                        <v-divider
                                            style="margin-bottom: 1%; border-color: rgb(56, 56, 56); width: 90%"
                                        />
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>
                <v-snackbar v-model="snackbar">
                    {{ snackbarMessage }}

                    <template v-slot:action="{ attrs }">
                        <v-btn text v-bind="attrs" color="white" @click="snackbar = false"> Close </v-btn>
                    </template>
                </v-snackbar>
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'

export default {
    name: 'PartsOrderStepper',
    props: {
        models: {
            type: Array,
            required: true
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        partsOrderWarningMessage: {
            type: String,
            required: false,
            default: ''
        },
        isPartOrderPaused: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            e1: this.step,
            selectedModel: null,
            serials: [],
            modelSerial: '',
            partsList: [],
            selectedSerials: [],
            partCounter: '',
            reachedMaxAmt: false,
            snackbar: false,
            snackbarMessage: '',
            newItem: {}
        }
    },
    computed: {
        ...mapGetters({
            cart: `cart/cart`
        }),
        isTraining() {
            return this.$auth.user && this.$auth.user.email === 'getaccomputing@usgetac.com'
        },
        unitImg() {
            const name = this.selectedModel.split(`-`)
            const fileName = (`` + name[0]).toLowerCase()
            return process.env.VUE_APP_PUBLIC_ASSETS_URL + `/images/units/${fileName}.png`
        }
    },
    methods: {
        highValueInCart() {
            let result = false
            const current = this.selectedSerials
            if (current) {
                const serialCart = this.cart.filter((e) => e.serials === current)
                result = serialCart.some((e) => e.isHighValue === 'Y')
            }
            return result
        },
        ...mapActions({
            add: `cart/add`
        }),
        checkMaxQty(selectedItem) {
            if (this.selectedSerials.length < 1) {
                this.snackbar = true
                this.snackbarMessage = 'Please select serial(s)'
            } else {
                const isHighValue = selectedItem.isHighValue
                if (isHighValue === 'N') {
                    const currentCart = this.cart.map((item) => ({
                        smId: item.uid,
                        category: item.category,
                        snList: item.serials,
                        isHighValue: item.isHighValue
                    }))
                    let serialCount = 0
                    currentCart.forEach((element) => {
                        if (this.selectedSerials === element.snList) {
                            serialCount++
                        }
                    })
                    if (serialCount < 2) {
                        this.add({
                            ...selectedItem,
                            model: this.selectedModel,
                            pn: selectedItem.pn,
                            serials: this.selectedSerials,
                            isHighValue: selectedItem.isHighValue
                        })
                        this.snackbar = true
                        this.snackbarMessage = selectedItem.partSpec + ' has been added to your cart.'
                    } else if (serialCount >= 2) {
                        this.partCounter = 'Maximum number of parts for this item has been reached. (Max: 2)'
                        this.reachedMaxAmt = true
                        this.snackbar = true
                        this.snackbarMessage = 'Failed to add: Maximum part quantity per serial exceeded'
                    } else {
                        this.snackbar = true
                        this.snackbarMessage = 'Error adding to cart'
                    }
                } else {
                    const currentCart = this.cart.map((item) => ({
                        smId: item.uid,
                        category: item.category,
                        snList: item.serials,
                        isHighValue: item.isHighValue
                    }))
                    let serialCount = 0
                    currentCart.forEach((element) => {
                        if (this.selectedSerials === element.snList) {
                            serialCount++
                        }
                    })
                    if (serialCount === 2) {
                        this.reachedMaxAmt = true
                        this.snackbar = true
                        this.snackbarMessage = 'Failed to add: Maximum part quantity per serial exceeded'
                    } else {
                        if (!this.highValueInCart()) {
                            this.add({
                                ...selectedItem,
                                model: this.selectedModel,
                                pn: selectedItem.pn,
                                serials: this.selectedSerials,
                                isHighValue: selectedItem.isHighValue
                            })
                            this.snackbar = true
                            this.snackbarMessage = selectedItem.partSpec + ' has been added to your cart.'
                        } else if (this.highValueInCart() && serialCount <= 1) {
                            this.snackbar = true
                            this.snackbarMessage = 'Failed to add: Maximum part 1 high value part per serial exceeded'
                        }
                    }
                }
            }
        },
        toCart() {
            this.$router.push({ path: '/parts-order/cart' })
        },
        getSNParts(serial) {
            this.partsList = []
            this.reachedMaxAmt = false
            this.partCounter = ''
            this.$apollo
                .query({
                    query: gql`
                        query QuerySMConfig($sn: String!) {
                            querySMConfig(sn: $sn) {
                                category
                                partSpec
                                pn
                                imgUrl
                                uid
                                isHighValue
                            }
                        }
                    `,
                    variables: {
                        sn: serial
                    }
                })
                .then((data) => {
                    this.partsList = data.data.querySMConfig
                })
        },
        getSNList(selectedModel) {
            this.partsList = []
            this.$apollo
                .query({
                    query: gql`
                        query QueryRegisteredSNList($model: String!) {
                            queryRegisteredSNList(model: $model) {
                                sn
                                assetTag
                                location
                                inwarranty
                            }
                        }
                    `,
                    variables: {
                        model: selectedModel
                    }
                })
                .then((data) => {
                    this.serials = data.data.queryRegisteredSNList.filter((e) => e.inwarranty === 'Yes')
                    this.modelSerial = data.data.queryRegisteredSNList[0]
                    this.selectedModel = selectedModel

                    this.partsList = []
                })
        }
    }
}
</script>

<style scoped>
#parts-select {
    margin-right: 100px;
}
.parts-image {
    margin-bottom: 5%;
    margin-right: 12px;
    max-width: 95px;
    height: auto;
    /* max-width: 75px; */
}
.product-card {
    margin: auto;
    margin-bottom: 10px;
    text-transform: uppercase;
    border-right: 1px solid rgb(56, 56, 56);
}
.product-card-bg {
    /* background-color: black; */
}
#model-select-heading {
    font-size: 36px;
    line-height: 0.9;
    letter-spacing: -0.1rem;
    margin-left: 10px;
    position: fixed;
    left: 0;
    /* bottom: 0; */
    margin-bottom: 100px;
    color: white;
}
.heading-line-two {
    color: #db5120 !important;
}
</style>
